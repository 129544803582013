@use 'common'

.wrapper
	position: relative
	opacity: 1
	visibility: visible
	transition-property: opacity, visibility
	transition-duration: 1s
	--Container-horizontal-spacing: var(--global-horizontal-spacing)

	max-width: calc(var(--Container-width) + 2 * var(--Container-horizontal-spacing))
	width: 100%
	padding-left: var(--Container-horizontal-spacing)
	padding-right: var(--Container-horizontal-spacing)
	margin: 0 auto

	&.view_size_normal
		--Container-width: var(--content-width-normal)

	&.view_size_wide
		--Container-width: var(--content-width-wide)

	&.view_size_fullWidth
		max-width: none

	&.view_disableGutters
		--Container-horizontal-spacing: 0px

	@media (min-width: common.$break48)
		opacity: 0
		visibility: hidden

.isInView
	opacity: 1
	visibility: visible

.Anchor
	position: absolute
	top: 30%
	left: 0
	right: 0
