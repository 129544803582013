@use '../vars'

=title
	font-size: 3.125rem
	line-height: 1
	font-family: vars.$font-title
	font-style: normal
	font-weight: 900
	text-align: center
	text-transform: uppercase
	@media (min-width: vars.$break375)
		font-size: 3.625rem
	@media (min-width: vars.$break48)
		font-size: 3.9375rem
	@media (min-width: vars.$break75)
		font-size: 4.0625rem
	@media (min-width: 90rem)
		font-size: 5.25rem
