@use 'common'
@use 'sass:math'

.ContactForm
	//

.ContactTypeNote
	margin-bottom: 0

.CheckboxWrapper
	margin: 2rem 0 0
	@media (min-width: common.$break48)
		margin: 3rem 0 0

.CheckboxLabel
	display: flex
	width: fit-content
	font-size: 20px
	line-height: 24px
	margin-bottom: 1rem
	cursor: pointer
	&:nth-child(1)
		margin-top: 0
	@media (min-width: common.$break48)
		margin-bottom: 2rem

.Checkbox
	-webkit-appearance: none
	appearance: none
	background-color: transparent
	margin: 0
	min-width: 1.75rem
	height: 1.75rem
	border: 1px solid
	border-radius: 50%
	transform: translateY(-0.075em)
	cursor: pointer

	&:checked
		background: #000
		background-image: url("/images/checkIcon.svg")
		background-repeat: no-repeat
		background-size: 100%

.CheckboxText
	+common.smallText
	display: flex
	align-items: center
	margin: 0
	padding-left: .75rem
	@media (min-width: common.$break48)
		+common.text

.InputLabel
	display: flex
	flex-direction: column

.InputLabelText
	margin-bottom: 1rem

.Input
	background: common.$beige
	// height: 3.75rem
	border: 1px solid
	border-radius: 1.25rem
	font-size: 1.25rem
	line-height: 1.5rem
	padding: 1rem
	font-family: common.$font-light

.InputFileWrapper
	position: relative
	border-radius: 0
	height: 12.5rem
	cursor: pointer

.InputFile
	visibility: hidden

.Plus
	position: absolute
	top: 50%
	left: 50%
	font-size: 2rem
	transform: translate(-50%, -50%)
	border: 1px solid #000
	background-color: #fff
	border-radius: 50%
	padding: 10px

.Image
	position: absolute
	top: 1rem

.EmailNote
	+common.smallText
	@media (min-width: common.$break48)
		+common.text

.Button
	+common.button
	margin-left: 0
	margin-top: 3rem
	@media not all and (min-width: common.$break48)
		background-color: #000
		color: common.$beige
		&:hover
			background-color: #000
			color: common.$beige

.ErrorMessage
	color: red
