@use 'common'
@use 'sass:math'

.GalleryInfo
	//

.Images
	display: flex
	flex-wrap: wrap
	margin: 0 -1rem

.ImageWrapper
	padding: 1rem
	width: 100%
	@media (min-width: common.$break48)
		width: 50%

.Image
	border-radius: .625rem
	overflow: hidden
	line-height: 0

.Texts
	display: flex
	justify-content: space-between
	padding: 2rem 0 1.5rem
	@media not all and (min-width: common.$break48)
		flex-direction: column

.TextFirst
	+common.perex

.TextSecond
	+common.text

.Link
	+common.button
