@use 'common'

$-gapMobile: 6.25rem
$-gapDesktop: 12.5rem

.wrapper
	position: relative
	width: 100%
	margin-top: 1rem
	> *:last-child
		&.section,
		&:not(.section)
			margin-bottom: $-gapMobile
			@media (min-width: common.$break48)
				margin-bottom: $-gapDesktop

.section

	& + &,
	*:not(.section) + &,
	& + *:not(.section)
		&:not(.is_reference_appTiles)
			margin-top: $-gapMobile
			@media (min-width: common.$break48)
				margin-top: $-gapDesktop
		&.is_reference_carouselWithArtworks,
		&.is_reference_personCarousel,
		&.is_reference_boxes,
		&.is_reference_events
			margin-top: 2rem
		&.is_reference_newsletter,
		&.is_reference_contactForm,
		&.is_reference_definitionList,
		&.is_reference_sponsors,
		&.is_reference_image,
		&.is_reference_dropDown
			margin-top: 3rem
		&.is_reference_chart,
		&.is_reference_button
			margin-top: 6.25rem

	&.is_reference_definitionList + *:not(.section)
		margin-top: 3rem
	&.is_reference_button + &.is_reference_button
		margin-top: 0

	&.is_reference_personCarousel + &.is_reference_dropDown
		margin-top: 1rem

.notImplemented
	border: 1px solid
	background-color: rgba(#ff0000, 0.07)
	color: #ff0000
	padding: 1em
	text-align: center

	&_name
		font-size: 2em
		text-transform: capitalize
