@use 'common'
@use 'sass:math'

.VennDiagram
	display: flex
	justify-content: space-around
	align-items: center
	position: relative
	height: 30rem
	max-width: 46rem
	margin: auto
	&::before
		content: ""
		border: 1px solid
		border-radius: 50%
		position: absolute
		top: 0
		left: 0
		width: 30rem
		height: 30rem
	&::after
		content: ""
		border: 1px solid
		border-radius: 50%
		position: absolute
		top: 0
		right: 0
		width: 30rem
		height: 30rem

.Logo
	position: relative

.Image
	display: flex

.Text
	+common.smallTitle
