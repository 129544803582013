@use 'common'

.BoxTile
	display: flex
	flex-direction: column
	border-top: 1px solid
	padding: 1.5625rem 1.25rem 2.1875rem
	@media (min-width: common.$break48)
		padding: 1.5625rem 2.5rem 2.1875rem

	&:nth-last-child(1)
		border-bottom: 1px solid

	&:hover
		background-color: #fff

.BoxInfo
	display: flex
	justify-content: space-between

.Title
	+common.smallTitle
	margin: 1.5625rem 0
	@media (min-width: common.$break48)
		text-align: left

.Date,
.Type,
.LeadParagraph
	margin: 0
