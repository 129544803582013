@use 'common'
@use 'sass:math'

.CarouselWithArtworks
	// --Container-horizontal-spacing: var(--global-horizontal-spacing)
	// @media (min-width: common.$break48)
	// 	padding-left: var(--Container-horizontal-spacing)
	// 	padding-right: var(--Container-horizontal-spacing)
	max-width: 120rem
	margin: auto

.TitleWrapper
	display: flex
	margin: 0 0 2rem
	@media not all and (min-width: common.$break48)
		flex-direction: column
		margin: 0
	@media	(min-width: 120rem)
		margin: 0 7rem 2rem

.Title
	+common.smallTitle
	text-align: start
	margin: 0
	margin-top: 4.5rem
	@media not all and (min-width: common.$break48)
		margin-bottom: 1rem

.CarouselArtworksWrapper
	position: relative
	&::after
		content: ""
		position: absolute
		top: 0
		right: 0
		left: 0
		height: 100%
		pointer-events: none
		@media (min-width: common.$break48)
			background: linear-gradient(90deg, rgba(255,255,255,0) 92%, rgba(#F5F3EF,1) 96%)

	@media (min-width: 120rem)
		&::before
			content: ""
			position: absolute
			top: 0
			right: 0
			left: 0
			height: 100%
			pointer-events: none
			z-index: 2
			background: linear-gradient(90deg, rgba(#F5F3EF,1) 2%, rgba(255,255,255,0) 6%)
.CarouselArtworks
	display: flex
	overflow-x: auto
	scrollbar-width: none
	scrollbar-height: none
	flex-wrap: nowrap
	scroll-snap-type: x mandatory
	width: 100%

	-ms-overflow-style: none /* Internet Explorer 10+ */
	scrollbar-width: none /* Firefox */
	&::before
		content: ""
		width: 1rem
		display: block
		-webkit-flex-shrink: 0
		flex-shrink: 0
		@media (min-width: 62rem)
			width: 2rem
		@media (min-width: 120rem)
			width: 9rem

	// @media (min-width: common.$break48)
	// 	width: auto

	&::-webkit-scrollbar
		display: none /* Safari and Chrome */

.ArtworkTile
	max-width: 55%
	flex-shrink: 0
	scroll-snap-align: center
	margin: .9375rem
	&:nth-child(1)
		margin-left: 0
	@media (min-width: common.$break48)
		max-width: 40%
	@media (min-width: common.$break62)
		max-width: 27%
	@media (min-width: common.$break75)
		max-width: 13%

.LastArtworkTile
	width: 5.5rem
	flex-shrink: 0

.ArtworkTitle
	font-size: 14px
	line-height: 17px
	font-family: common.$font-text
	margin-top: 1.25rem

.Arrows
	display: none
	justify-content: flex-end
	@media (min-width: common.$break48)
		display: flex
	@media (min-width: 120rem)
		margin: 0 7rem 2rem

.ArrowBackward,
.ArrowForward
	visibility: hidden
	font-size: 1.25rem
	z-index: 1
	background-color: transparent
	border: none
	cursor: pointer
	padding: 1rem 1.1rem
	margin: 0 1rem 0 0.5rem
	border-radius: 50%
	border: 1px solid transparent
	display: flex

.isVisible
	visibility: visible

	&:hover
		background-color: #fff
		border: 1px solid

.ArrowBackward
	transform: rotate(90deg)

.ArrowForward
	transform: rotate(-90deg)

.ButtonDesktop,
.ButtonMobile
	+common.button
	display: flex
	width: fit-content
	margin-left: auto
	margin-bottom: 0
	@media not all and (min-width: common.$break48)
		margin: auto

.ButtonDesktop
	display: none
	@media (min-width: common.$break48)
		display: flex

.ButtonMobile
	margin-top: 1rem
	@media (min-width: common.$break48)
		display: none
