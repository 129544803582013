@use 'common'
@use 'sass:math'

.HomePage
	//

.Branches
	display: flex
	flex-wrap: wrap
	margin: 2rem 0 9.375rem

.Branch
	width: 100%
	padding: 1rem 2rem 2rem
	border-radius: .625rem
	background-color: #fff
	margin: 1rem 0
	&:hover
		background-color: #fff
		.BranchMore
			visibility: visible
		.BranchTitle
			color: #000
			-webkit-text-stroke: #fff
		.Arrow
			visibility: visible

	@media (min-width: common.$break48)
		width: math.div(100%, 2)
		background-color: transparent
		margin: 0
	@media (min-width: common.$break62)
		width: math.div(100%, 3)

.BranchTitle
	+common.title
	text-align: start
	margin: 0
	overflow: hidden
	@media (min-width: common.$break48)
		-webkit-text-stroke: 1px #000
		color: common.$beige
	@media (min-width: common.$break62)
		font-size: 3.125rem
	@media (min-width: common.$break75)
		+common.title
		text-align: start

.BranchTitleLastWord
	white-space: nowrap

.Arrow
	display: inline-flex
	visibility: visible
	height: 1em
	align-items: center
	position: relative
	top: -0.15em
	left: 0.5em
	@media (min-width: common.$break48)
		visibility: hidden

.ArrowIn
	display: flex
	transform: rotate(-90deg)
	font-size: 1.25rem
	@media (min-width: common.$break48)
		font-size: 2rem

.BranchLeadParagraph,
.BranchMore
	font-size: 1.25rem
	line-height: 1.5rem

.BranchMore
	@media (min-width: common.$break48)
		visibility: hidden
