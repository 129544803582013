@use 'sass:math'
@use 'common'

.wrapper
	//

.image
	// max-width: 262px
	margin: 0 auto

	@supports not (-webkit-touch-callout: none)
		&_in
			transform: translate(var(--LogoArte-x, 0), var(--LogoArte-y, 0)) scale(var(--LogoArte-scale, 1))
