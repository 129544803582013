@use 'common'
@use 'sass:math'

.ContactPage
	margin-bottom: 7rem

.Title
	+common.title
	text-align: center

.ContactSection
	+common.text
	display: flex
	flex-direction: column
	align-items: flex-start
	padding: 1rem 0
	@media (min-width: common.$break48)
		border-top: 1px solid
		flex-direction: row
		justify-content: space-between

.MobileTopLine
	border-top: 1px solid
	margin-top: 2rem

.Address
	+common.perex
	margin-bottom: 1.5rem

.IcoDicWrapper
	margin: 0.5rem 0 1.5rem

.Ico,
.Dic
	margin: 0.25rem 0

.Socials
	display: flex
	flex-wrap: wrap
	// @media not all and (min-width: common.$break375)
	// 	flex-direction: column
	// 	align-items: flex-start
	// 	margin-bottom: 1rem

.DataBoxNumber,
.SecuritiesNumber
	margin: 0
	display: flex
	margin-top: 1rem
	@media (min-width: common.$break48)
		margin-top: 0
		text-align: end

.AddressLink,
.Phone,
.Email,
.Instagram,
.Youtube,
.Facebook,
.LinkedIn
	+common.button
	margin: 1.5rem 0 0
	@media (min-width: common.$break48)
		margin: 0 0 1.5rem

.Instagram,
.Youtube,
.Facebook,
.LinkedIn
	margin: 0.5rem 0.5rem 0 0
	width: auto
