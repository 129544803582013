@use 'sass:math'

// Normalizes height to 1em while preserving aspect ratio
=normalizeSize($intrinsicWidth, $intrinsicHeight)
	$height: 1em

	width: math.div($intrinsicWidth, $intrinsicHeight) * $height
	height: $height

.icon
	// Add all svg icons and corresponding dimensions

	&-arrowBig
		+normalizeSize(345, 64)
	&-arrowDown
		+normalizeSize(22, 28)
	&-arte
		+normalizeSize(742, 328)
	&-cross
		+normalizeSize(166, 78)
	&-go
		+normalizeSize(512, 512)
	&-hamburger
		+normalizeSize(166, 20)
	&-hamburgerMobile
		+normalizeSize(64, 37)
	&-play
		+normalizeSize(43, 44)
	&-plus
		+normalizeSize(32, 32)
	&-pro
		+normalizeSize(578, 337)
