@use 'common'
@use 'sass:math'

.LogoList
	display: flex
	flex-wrap: wrap

.ImageWrapper
	display: flex
	justify-content: center
	width: 50%
	@media (min-width: common.$break30)
		width: math.div(100%, 3)
	@media (min-width: common.$break48)
		width: math.div(100%, 4)
		margin: 1rem 0
	@media (min-width: common.$break62)
		justify-content: flex-start
		width: math.div(100%, 5)
	@media (min-width: common.$break75)
		width: math.div(100%, 6)

.Image
	display: flex
	position: relative
	width: 100px
	height: 100px
	overflow: hidden
