@use 'common'
@use 'sass:math'

.Artwork
	//

.GalleryWrapper,
.ImageWrapper
	position: relative
	// &::after
	// 	content: ""
	// 	position: absolute
	// 	top: 0
	// 	right: 0
	// 	left: 0
	// 	height: 100%
	// 	pointer-events: none
	// 	@media (min-width: common.$break48)
	// 		background: linear-gradient(90deg, rgba(255,255,255,0) 92%, rgba(#F5F3EF,1) 96%)

	// @media (min-width: 120rem)
	// 	&::before
	// 		content: ""
	// 		position: absolute
	// 		top: 0
	// 		right: 0
	// 		left: 0
	// 		height: 100%
	// 		pointer-events: none
	// 		z-index: 2
	// 		background: linear-gradient(90deg, rgba(#F5F3EF,1) 2%, rgba(255,255,255,0) 6%)

.Gallery
	display: flex
	overflow-x: auto
	scrollbar-width: none
	scrollbar-height: none
	flex-wrap: nowrap
	scroll-snap-type: x mandatory
	width: 100%

	-ms-overflow-style: none /* Internet Explorer 10+ */
	scrollbar-width: none /* Firefox */
	&::before
		content: ""
		width: 1rem
		width: 22.5%
		display: block
		-webkit-flex-shrink: 0
		flex-shrink: 0

	&::-webkit-scrollbar
		display: none /* Safari and Chrome */

.GalleryItem
	display: flex
	justify-content: center
	width: 55%
	flex-shrink: 0
	scroll-snap-align: center

.LastGalleryItem
	width: 22.5%
	flex-shrink: 0

.Image
	display: flex
	justify-content: center
	margin: 6.25rem 0
	width: 100%
	height: 50vh

.GalleryImage
	display: flex
	justify-content: center
	margin: 6.25rem 0 2.25rem
	width: auto
	max-height: 50vh
	width: 100%

.GalleryButton
	border: none
	background-color: transparent
	cursor: pointer

.Title
	+common.title
	margin: 0

.Label
	font-size: 20px
	line-height: 24px
	font-weight: 300
	text-align: center
	padding-top: 1.3125rem

.LeadParagraph
	+common.perex
	padding-top: 4.6875rem

.ArrowWrapper
	display: flex
	flex-direction: column
	margin-bottom: 2rem
	@media (min-width: common.$break48)
		justify-content: space-between
		flex-direction: row

.ArrowSection
	width: math.div(100%, 3)

.FullScreenButton
	+common.text
	width: fit-content
	padding: .625rem 1.25rem
	border: 1px solid
	border-radius: 3.125rem
	background-color: transparent
	margin: auto
	cursor: pointer
	&:hover
		background: #fff

.Arrows
	display: none
	justify-content: flex-end
	@media (min-width: common.$break48)
		display: flex
	@media (min-width: 120rem)
		margin-bottom: 2rem

.ArrowBackward,
.ArrowForward
	visibility: hidden
	font-size: 1.25rem
	z-index: 1
	background-color: transparent
	border: none
	cursor: pointer
	padding: 1rem 1.1rem
	margin: 0 1rem 0 0.5rem
	border-radius: 50%
	border: 1px solid transparent
	display: flex

.isVisible
	visibility: visible

	&:hover
		background-color: #fff
		border: 1px solid

.ArrowBackward
	transform: rotate(90deg)

.ArrowForward
	transform: rotate(-90deg)
