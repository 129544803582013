@use 'common'
@use 'sass:math'

.Button
	display: flex
	justify-content: center
	width: fit-content
	margin: auto
	padding: 1rem
	&:hover
		.Arrow
			visibility: visible

.Link
	+common.title
	align-items: center
	text-transform: uppercase
	-webkit-text-stroke: 1px #000
	color: common.$beige
	margin: 0
	&:hover
		color: #000
		-webkit-text-stroke: #fff

.TitleLastWord
	white-space: nowrap

.Arrow
	display: inline-flex
	visibility: visible
	transform: rotate(-90deg)
	padding-left: 0.25em
	padding-top: 1rem
	font-size: 0.5em
	vertical-align: middle

	@media (min-width: common.$break48)
		visibility: hidden
