@use 'common'
@use 'sass:math'

.DropDown

.DropDownItem
	border-top: 1px solid
	transition: background-color 0.3s
	&:nth-last-child(1)
		border-bottom: 1px solid

// .DropDownContent
// 	display: none

.isOpen
	background-color: #fff
	.Arrow
		display: flex
		justify-content: flex-end
		transform: rotate(-180deg)

.DropDownContent
	display: flex
	flex-direction: column
	align-items: flex-start
	padding: 0 1.25rem
	transition: 1s
	@media (min-width: common.$break48)
		padding: 0 2.5rem

.Title
	+common.smallTitle
	cursor: pointer
	background: transparent
	border: none
	width: 95%
	display: flex
	justify-content: space-between
	align-items: center
	text-align: left
	padding: .9375rem 1.25rem .9375rem 1.25rem
	@media (min-width: common.$break48)
		padding: .9375rem 0 .9375rem 2.5rem

.Arrow
	font-size: 1.5rem

.Image
	border-radius: 10px
	overflow: hidden
	width: 25%
	margin-bottom: 1.5625rem

.Text
	+common.perex

.ButtonWrapper
	display: flex
	margin: 2rem 0.5rem 2.75rem 0

	@media not all and (min-width: common.$break48)
		flex-direction: column
		width: 100%

.Button,
.ButtonSecond
	+common.button
	font-size: 1rem
	margin: 0
	width: fit-content
	@media (min-width: common.$break30)
		+common.button

.ButtonSecond
	@media not all and (min-width: common.$break48)
		margin-top: 1.5rem
