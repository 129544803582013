@use 'sass:math'
@use 'common'

.HeaderSticky
	position: sticky
	top: 0
	z-index: 2
	border-bottom: 1px solid
	background: common.$beige
	height: 94px
	&.isHeaderOpen
		z-index: 3

.isHomePageBorder
	border-bottom: 0

.isHomePageBorderVisible
	border-bottom: 1px solid

.Header
	display: flex
	justify-content: space-between
	align-items: center
	min-height: 94px

.isHomePage
	.Breadcrumbs
		display: none
	.IconHamburger
		width: 25%
	@supports not (-webkit-touch-callout: none)
		.Logo
			display: none
	@media (min-width: common.$break48)
		justify-content: flex-end

.Breadcrumbs,
.Logo,
.IconHamburger,
.Locales
	width: math.div(100%, 3)

.Logo
	@media not all and (min-width: common.$break48)
		width: 65%

.Breadcrumbs
	display: none
	@media (min-width: common.$break48)
		display: flex
		align-items: center

.IconHamburgerMobileWrapper
	display: flex
	justify-content: flex-end
	width: 25%
	@media (min-width: common.$break48)
		display: none
	@media not all and (min-width: common.$break48)
		width: 35%

.Logo
	display: flex
	justify-content: center

@supports (-webkit-touch-callout: none)
	.HomePageLogo
		display: none
@supports not (-webkit-touch-callout: none)
	.HomePageLogo
		display: flex
		justify-content: center
		width: 65%
		@media (min-width: common.$break48)
			width: 50%

.Pro
	position: relative
	aspect-ratio: 16/9
	width: 6.35rem
	margin-top: -3px

.Arte
	position: relative
	aspect-ratio: 16/9
	width: 8.1rem
	margin-top: -3px

.Links
	display: none
	flex-direction: column
	align-items: center
	z-index: 2
	margin: 2rem 0
	@media (min-width: common.$break48)
		margin: 4rem 0

.Link
	+common.title
	display: flex
	align-items: center
	-webkit-text-stroke: 1px #000
	color: #fff
	&:hover
		-webkit-text-stroke: #fff
		color: #000
		.Arrow
			visibility: visible

	@media (min-width: common.$break48)
		font-size: 5.25rem

.LinkTitle
	margin: 0
	font-size: inherit
	line-height: inherit

.isActiveLink
	color: #000

.Arrow
	display: none
	visibility: hidden
	font-size: 1rem
	transform: rotate(-90deg)
	width: 0
	height: 0
	position: relative
	left: 0.25em
	top: 0.25em
	@media	(min-width: common.$break48)
		display: block
		font-size: 2rem

.IconHamburger,
.IconHamburgerMobile
	justify-content: flex-end
	border: none
	background-color: transparent
	font-size: 1.25rem
	cursor: pointer
	padding: 0

.IconHamburger
	display: none
	@media (min-width: common.$break48)
		display: flex

.IconHamburgerMobile
	display: flex
	font-size: 2.8125rem
	@media	(min-width: common.$break375)
		font-size: 3.125rem
	@media	(min-width: common.$break48)
		font-size: 35px

.Close
	display: flex
	justify-content: flex-end
	font-size: 2.5rem
	padding-top: 1rem
	@media (min-width: common.$break48)
		font-size: 4.75rem

.HeaderOpen
	display: none
	flex-direction: column
	position: absolute
	inset: 0
	background-color: #fff
	height: fit-content
	z-index: 2
	border-bottom: 1px solid

.Locales
	display: flex

.LocaleWrapper
	display: flex
	align-items: center

.MissingLocale
	display: none

.Locale
	text-transform: capitalize
	color: #8D8D8D
	font-size: 20px
	line-height: 24px

.Dot
	width: 0.5rem
	height: 0.5rem
	background-color: #000
	margin: 0 1rem
	border-radius: 50%

.isActiveLocale
	color: #000

.isActive
	display: flex

	.Header
		@media not all and (min-width: common.$break48)
			flex-direction: column

	.Locales
		@media not all and (min-width: common.$break48)
			width: 100%
			justify-content: center
			margin: 2rem 0 1rem

	.IconHamburger
		width: math.div(100%, 3)

	.Close
		@media not all and (min-width: common.$break48)
			display: none

	.Logo
		display: flex
		@media not all and (min-width: common.$break48)
			width: 200px
		@media not all and (min-width: common.$break375)
			width: 175px

	.CloseMobile
		font-size: 3rem
		width: 100%
		display: flex
		justify-content: center
		margin-bottom: 2rem
		@media (min-width: common.$break48)
			display: none

	.Link
		display: flex
