@use 'common'
@use 'sass:math'

.StrategySchema
	//

.Input,
.Output,
.Text
	margin: .625rem 0

.Image
	display: flex
	align-items: center

.Section
	display: flex
	justify-content: space-between
	@media not all and (min-width: common.$break48)
		flex-direction: column
		align-items: center

.Arrows
	display: flex
	justify-content: center
	overflow: hidden

.ArrowLeft,
.ArrowRight
	display: flex
	align-items: center
	font-size: 3.75rem
	margin: 1rem

.ArrowRight
	transform: rotateY(-180deg)

.ItemsFirst,
.ItemsSecond
	display: flex
	flex-direction: column
	align-items: center
	margin: 2rem 0
.ItemsFirst
	margin: 0 0 2rem
.ItemsSecond
	margin: 2rem 0 0

@media (min-width: common.$break48)
	.Section
		margin: 2rem 0
		&:nth-child(1)
			margin-bottom: 3rem
		&:nth-last-child(1)
			margin-top: 3rem

	.Input,
	.Output,
	.ItemsFirst,
	.ItemsSecond,
	.Image
		width: math.div(100%, 3)
		display: flex
		justify-content: center
		margin: 0

	.ItemsFirst
		align-items: flex-start

	.ItemsSecond
		align-items: flex-end

	.Input,
	.Output
		&:nth-child(1)
			justify-content: flex-start

		&:nth-last-child(1)
			justify-content: flex-end
