@use 'common'
@use 'sass:math'

.ArtworkSelection
	//

.Artworks
	display: flex
	flex-wrap: wrap
	@media (min-width: common.$break48)
		margin-left: -1rem

.Title
	text-align: center
	margin: 4rem

.Artwork
	width: 100%
	@media (min-width: common.$break48)
		width: math.div(100%, 3)
		padding: 1rem
	@media (min-width: common.$break62)
		width: 20%

.ArtworkTitle
	margin: 0.5rem 0 3rem
