@use 'common'

.BreadcrumbTitle
	+common.smallText
	color: common.$grey
	&:hover
		color: #000

	&:nth-last-child(1)
		color: #000

.Dot
	width: 0.5rem
	height: 0.5rem
	background-color: #000
	margin: 0 0.5rem
	border-radius: 50%
