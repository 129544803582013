@use 'common'

.Footer
	margin-bottom: 3.5rem

.Contacts
	display: flex
	flex-direction: column
	white-space: nowrap
	margin: 1rem 0
	@media (min-width: common.$break62)
		margin: 0 1rem

.Info
	display: flex
	justify-content: space-between
	align-items: flex-start
	margin-bottom: 2.5rem
	font-size: 1.25rem
	line-height: 1.5rem

	@media not all and (min-width: common.$break62)
		flex-direction: column

.Socials
	display: flex
	flex-wrap: wrap

	.Social
		width: auto

.Social
	+common.button
	margin: 0.5rem 0.5rem 0 0

.MadeBy
	display: flex
	margin-top: 1rem
	@media not all and (min-width: common.$break62)
		flex-direction: column
	@media (min-width: common.$break62)
		align-items: center

.Note
	+common.smallText
	padding-right: 0.25rem
	margin: 0

.Link
	+common.smallText
	text-decoration: underline
	&:hover
		text-decoration: none

.GraphicDesigners,
.Photographers,
.Development
	display: flex
	align-items: center

.GraphicDesigner,
.Photographer
	display: flex
	align-items: center
	&:nth-child(2)
		.Ampersand
			display: none

.Ampersand
	+common.smallText
	padding: 0.25rem
	margin: 0

.Dot
	display: none
	@media (min-width: common.$break62)
		display: flex
		width: .6875rem
		height: .6875rem
		background-color: #000
		border-radius: 50%
		margin: 0 1rem

.isContactPage
	.Info
		display: none
