@use 'common'
@use 'sass:math'

.DefinitionList
	//

.Definition
	+common.text
	display: flex
	border-top: 1px solid
	padding: 1rem 0
	@media not all and (min-width: common.$break48)
		flex-direction: column

.Key
	margin: 0 0 2rem
	@media (min-width: common.$break48)
		margin: 0
		min-width: 20rem
	@media (min-width: common.$break62)
		min-width: 22rem

.Value
	@media not all and (min-width: common.$break48)
		opacity: 0.5
