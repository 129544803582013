@use 'common'
@use 'sass:math'

@keyframes scaleUp
	0%
		transform: scale(1.1)
	100%
		transform: scale(1)

.Animation
	display: flex
	flex-direction: column
	padding-top: 1rem
	z-index: 2
	pointer-events: none
	padding-left: var(--Container-horizontal-spacing)
	padding-right: var(--Container-horizontal-spacing)
	@supports not (-webkit-touch-callout: none)
		--Container-horizontal-spacing: var(--global-horizontal-spacing)
		--HomePageAnimation-animation-capped: max(0, var(--HomePage-animation))
		inset: 0
		position: fixed

	@media (min-width: common.$break48)
		padding-top: 5.875rem

.LeadParagraphWrapper
	display: flex

.Pro,
.LeadParagraphDesktop
	display: flex
	width: 50%

.Arte
	display: flex
	justify-content: flex-end
	margin-top: 1rem
	@media (min-width: common.$break48)
		margin-top: -6.25%

.LeadParagraphDesktop
	display: none

	@media (min-width: common.$break48)
		font-size: 1.5rem
		display: block
		max-width: 28rem
		margin: 0
		opacity: (calc(1 / (1 + 0.25 * var(--HomePageAnimation-animation-capped))))
		padding-left: 0.5rem
		@media (min-width: common.$break62)
			+common.perex

.LeadParagraphMobile
	+common.perex
	width: 100%
	@supports not (-webkit-touch-callout: none)
		opacity: (calc(1 / (1 + 0.25 * var(--HomePageAnimation-animation-capped))))
	@media (min-width: common.$break48)
		display: none
	@supports (-webkit-touch-callout: none)
		padding: 0 1rem
		margin-top: 0rem

.Pro,
.Arte
	font-size: 25vw
	line-height: 0
	@supports (-webkit-touch-callout: none)
		display: none

.ImageWrapper
	position: relative
	overflow: hidden

	height: calc(100vh - 94px)
	@supports not (-webkit-touch-callout: none)
		height: calc(100vh - 94px)
		margin-top: calc(50vh - 94px)

.Image
	visibility: hidden
	opacity: 0
	transition-property: visibility, opacity
	transition-duration: 1.5s
	position: absolute
	inset: 0
	&.isActive
		visibility: visible
		opacity: 1
		animation-timing-function: linear
		animation-name: scaleUp
		animation-duration: 5s
		animation-fill-mode: forwards
