@use 'common'

@keyframes fadeOut
	0%
		opacity: 1
	100%
		opacity: 0

.FlashMessage
	animation: fadeOut 1s 4s forwards
