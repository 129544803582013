@use '../vars'

=smallTitle
	font-size: 2.3125rem
	line-height: 1
	font-family: vars.$font-title
	font-style: normal
	font-weight: 900
	text-align: center
	text-transform: uppercase
	color: #000
	@media (min-width: vars.$break48)
		font-size: 3rem
