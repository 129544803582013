@use 'common'
@use 'sass:math'

$highestColumnHeight: 35vh

.Chart
.Chart
	//

.ChartColumnWrapper
	position: relative

.ChartColumn
	display: flex
	overflow-x: auto
	scrollbar-width: none
	scrollbar-height: none
	flex-wrap: nowrap
	scroll-snap-type: x mandatory
	width: 100%
	align-items: flex-end

	-ms-overflow-style: none /* Internet Explorer 10+ */
	scrollbar-width: none /* Firefox */

	&::before
		content: ""
		width: 1rem
		display: block
		-webkit-flex-shrink: 0
		flex-shrink: 0
		@media (min-width: 62rem)
			width: 2rem
		@media (min-width: 120rem)
			width: 9rem

	&::-webkit-scrollbar
		display: none /* Safari and Chrome */
	@media (min-width: common.$break48)
		justify-content: center
	// &::after
	// 	content: ""
	// 	position: absolute
	// 	top: 0
	// 	right: 0
	// 	left: 0
	// 	height: 100%
	// 	pointer-events: none

	// 	background: linear-gradient(90deg, rgba(255,255,255,0) 92%, rgba(#F5F3EF,1) 96%)

.ChartColumnLine
	flex-shrink: 0
	display: flex
	flex-direction: column
	align-items: center
	padding: 0 0.5rem
	scroll-snap-align: center

	&:hover
		background-color: #fff
		cursor: pointer
		border-radius: 10px

.Lines
	display: flex
	height: calc(var(--ChartColumn-heightFraction) * #{$highestColumnHeight})
	width: 1rem
	border: 1px solid

.ColumnInfoText,
.Date
	+common.smallText

// .Line
// 	border-left: 1px solid #000
// 	width: 1rem
// 	&:nth-last-child(1)
// 		width: 0

.PieGraph
	width: 300px
	height: 300px
	position: relative
	margin: auto
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	overflow: hidden
	@media (min-width: common.$break48)
		overflow: unset
		width: 400px
		height: 400px
	@media (min-width: common.$break62)
		width: 600px
		height: 600px

.MobileTextsWrapper
	margin-top: 2rem
	@media (min-width: common.$break48)
		display: none

.MobileTexts
	display: flex
	justify-content: space-between

.MobileText,
.MobileNumber
	+common.text
	margin: 0.75rem 0

.CirclesWrapper
	//
	display: flex

.Circles
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	transform: rotate(calc(var(--PieGraph-rotationDegrees) * 1deg))
	pointer-events: none

.CirclesText
	display: none
	@media (min-width: common.$break48)
		display: block
		position: absolute
		top: 50%
		left: 50%
		width: 50%
		transform: rotate(calc(var(--PieGraph-textRotationDegrees) * 1deg))
		transform-origin: center left
		height: 1px

.CirclesTextIn
	@media (min-width: common.$break48)
		position: absolute
		transform: rotate(calc(var(--PieGraph-textRotationDegrees) * -1deg))
		padding: 1rem

		.isPositionRightBottom &
			top: 0
			left: 100%
			transform-origin: 0 0

		.isPositionLeftBottom &
			transform-origin: 100% 0
			top: 0
			right: 0
			text-align: end

		.isPositionLeftTop &
			transform-origin: 100% 100%
			right: 0
			bottom: 0
			text-align: end

		.isPositionRightTop &
			transform-origin: 0 100%
			left: 100%
			bottom: 0

	p
		margin: 0 // todo

	&:hover
		background-color: #fff
		border-radius: .625rem
		.CircleHover
			display: flex

.CircleHover
	display: none
	width: 11rem
	object-fit: cover
	margin-top: 0.5rem

.CircleTitle
	white-space: nowrap

.Arrows
	display: flex
	justify-content: flex-end
	@media (min-width: common.$break48)
		display: none
	@media (min-width: 120rem)
		margin: 0 7rem 2rem

.ArrowBackward,
.ArrowForward
	visibility: hidden
	font-size: 1.25rem
	z-index: 1
	background-color: transparent
	border: none
	cursor: pointer
	padding: 1rem 1.1rem
	margin: 0 1rem 0 0.5rem
	border-radius: 50%
	border: 1px solid transparent
	display: flex

.isVisible
	visibility: visible

	&:hover
		background-color: #fff
		border: 1px solid

.ArrowBackward
	transform: rotate(90deg)

.ArrowForward
	transform: rotate(-90deg)
