@use 'sass:math'
@use 'common'

.wrapper
	//

.image
	// max-width: 262px
	margin: 0 auto
	transform: translate(0) scale(1)
	@supports not (-webkit-touch-callout: none)
		&_in
			transform: translate(var(--LogoPro-x, 0), var(--LogoPro-y, 0)) scale(var(--LogoPro-scale, 1))
