@use 'common'
@use 'sass:math'

.GalleryWrapper
	position: relative
	&::after
		content: ""
		position: absolute
		top: 0
		right: 0
		left: 0
		height: 100%
		pointer-events: none
		@media (min-width: common.$break48)
			background: linear-gradient(90deg, rgba(255,255,255,0) 92%, rgba(#F5F3EF,1) 96%)

	@media (min-width: 120rem)
		&::before
			content: ""
			position: absolute
			top: 0
			right: 0
			left: 0
			height: 100%
			pointer-events: none
			z-index: 2
			background: linear-gradient(90deg, rgba(#F5F3EF,1) 2%, rgba(255,255,255,0) 6%)

.Gallery
	display: flex
	flex-wrap: wrap
	margin: 3rem 0 2rem
	overflow-x: auto
	scrollbar-width: none
	scrollbar-height: none
	flex-wrap: nowrap
	scroll-snap-type: x mandatory

	-ms-overflow-style: none /* Internet Explorer 10+ */
	scrollbar-width: none /* Firefox */

	&::before
		content: ""
		width: 1rem
		display: block
		-webkit-flex-shrink: 0
		flex-shrink: 0
		@media (min-width: 62rem)
			width: 2rem
		@media (min-width: 120rem)
			width: 9rem

	&::-webkit-scrollbar
		display: none /* Safari and Chrome */

.ItemWrapper
	width: 100%
	flex-shrink: 0
	scroll-snap-align: center

.ItemWrapper:first-of-type:not(:last-of-type),
.ItemWrapper:not(:first-of-type)
	max-width: 80%
	@media (min-width: common.$break48)
		max-width: 44%

	@media (min-width: common.$break62)
		max-width: 35%

//increase specificity css
.YoutubeEmbed.YoutubeEmbed.YoutubeEmbed
	max-width: 80%
	@media (min-width: common.$break48)
		max-width: 50%

.Item
	margin: 1rem

.Button
	cursor: pointer
	transition-duration: 0.5s
	border: none
	background-color: transparent
	width: 100%
	border-radius: .625rem
	overflow: hidden
	padding: 0
	line-height: 0

	&:hover
		transform: scale(1.03)

.ImageWrapper
	position: relative

.CarouselWrapper
	display: flex
	align-items: center

.Play
	position: absolute
	z-index: 2
	left: 50%
	top: 50%
	font-size: 2rem
	transform: translate(-50%, -50%)
	pointer-events: none

.Image,
.Video
	cursor: pointer
	overflow: hidden
	border-radius: .625rem

.Video
	position: relative
	width: 100%
	padding-top: 56.25%

.VideoIframe
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%

.Arrow
	display: none
	margin: 0 0.5rem

.Back
	transform: rotateZ(180deg)

.isVisible
	display: flex
	@media (min-width: common.$break30)
		display: none

.Arrows
	display: flex
	justify-content: flex-end
	@media	(min-width: 120rem)
		margin: 0 7rem 2rem

.ArrowBackward,
.ArrowForward
	visibility: hidden
	font-size: 1.25rem
	z-index: 1
	background-color: transparent
	border: none
	cursor: pointer
	padding: 1rem 1.1rem
	margin: 0 1rem 0 0.5rem
	border-radius: 50%
	border: 1px solid transparent
	display: flex

.isVisible
	visibility: visible

	&:hover
		background-color: #fff
		border: 1px solid

.ArrowBackward
	transform: rotate(90deg)

.ArrowForward
	transform: rotate(-90deg)

.LastTile
	width: 5.5rem
	flex-shrink: 0
	@media (min-width: 120rem)
		width: 10rem
