@use 'common'

.wrapper
	// margin-top: 5.625rem
	// margin-bottom: 1.875rem
	// @media (min-width: common.$break48)
	// 	margin-top: 8.75rem
	// 	margin-bottom: 3.75rem

	[data-contember-align="center"]
		text-align: center
		margin-left: auto
		margin-right: auto
	[data-contember-align="end"]
		text-align: end

	[data-contember-type="scrollTarget"]
		scroll-margin-top: 8rem
	a
		text-decoration: underline

	h1,
	h2,
	h3
		@media not all and (min-width: common.$break48)
			br
				display: none

	h2
		+common.title
		margin-top: 6.25rem
		margin-bottom: 2rem
		@media (min-width: common.$break48)
			margin-top: 12.5rem

	h3
		+common.smallTitle
		margin-top: 6.25rem
		@media (min-width: common.$break48)
			margin-top: 12.5rem

	h4
		+common.perex
		margin-top: 1rem

	h6
		+common.smallText
		font-weight: 400

	em
		font-style: normal
		color: red

	ul,
	ol
		max-width: 53rem

	ol
		width: fit-content
		counter-reset: item
		list-style-type: none
		padding: 0
		li
			width: fit-content
			padding: 0.75rem 0
			text-align: start

			&::before
				content: counter(item) " — "
				counter-increment: item

	ol[data-contember-align="center"]
		margin: auto
		li

	p
		max-width: 53rem
		min-height: 1rem
		font-size: 20px
		line-height: 24px

		@media not all and (min-width: common.$break48)
			br
				display: none

	// [data-contember-align="center"]
	// 	text-align: center
	// 	margin: auto
	// [data-contember-align="end"]
	// 	text-align: end
