@use 'common'
@use 'sass:math'

.EventList
	//

.Event
	display: flex
	flex-direction: column
	border-top: 1px solid
	padding: 1.5625rem 2.5rem 2.1875rem

	&:nth-last-child(1)
		border-bottom: 1px solid

.EventInfo
	display: flex
	justify-content: space-between

.Title
	margin: 1.5625rem 0
	font-weight: 900
	font-size: 48px
	line-height: 58px
	text-transform: uppercase

.Date,
.Type,
.LeadParagraph
	margin: 0
