@use 'common'
@use 'sass:math'

.Event

.ImageTextWrapper
	position: relative
	margin-top: -6.375rem
	height: 85vh
	display: flex
	align-items: flex-end

.Image
	width: 100%

.Lines
	display: flex
	flex-direction: column
	position: absolute
	top: 6.375rem
	left: 0
	right: 0
	margin-top: 1.5625rem

.Line
	border-top: 1px solid #fff
	width: 100%
	height: 1.5625rem

.Title
	--Container-horizontal-spacing: var(--global-horizontal-spacing)

	+common.title
	position: absolute
	bottom: 3.75rem
	left: 0
	right: 0
	display: flex
	justify-content: center
	color: common.$beige
	max-width: 59rem
	margin: auto
	padding-left: var(--Container-horizontal-spacing)
	padding-right: var(--Container-horizontal-spacing)

.VenueWrapper
	display: flex
	justify-content: space-between
	padding: 1rem 0
	@media not all and (min-width: common.$break48)
		flex-direction: column

.Venue,
.Date
	+common.perex

.VenueNote
	+common.text
	@media not all and (min-width: common.$break48)
		margin-top: 1rem

.Border
	border-top: 1px solid

.LeadParagraph
	+common.perex

.isDark
	.Line
		border-top: 1px solid #000
	.Title
		color: #000
