@use '../vars'

=button
	font-size: 1.25rem
	line-height: 1.2
	font-weight: 300
	font-family: vars.$font-light
	background-color: transparent
	border: 1px solid
	margin: auto 0.5rem
	padding: 0.5rem 1rem
	border-radius: 1.25rem
	cursor: pointer
	color: #000

	@media not all and (min-width: vars.$break48)
		width: 100%
		justify-content: center
		text-align: center

	&:hover
		background-color: #fff
